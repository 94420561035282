/* eslint-disable jsx-a11y/alt-text */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { fetchTemplateGenres, TemplateGenreSlugEnum } from '@storylinerlabs/templates';

// import types
import type { GetStaticProps, NextPage } from 'next';
import type { TemplateGenre } from '@storylinerlabs/templates';
import { RouteEnum } from '../types';

// import modules
import { fetchBlogPosts } from '../modules/fetcher';

// import components
import HomeTemplate from '../components/templates/HomeTemplate';

// define page
const IndexPage: NextPage<{
  blogPosts: { image: string; slug: string; tags: string[]; title: string }[];
  hrefLang: string;
  templateGenres: TemplateGenre[];
}> = ({ blogPosts, hrefLang, templateGenres }) => (
  <HomeTemplate blogPosts={blogPosts} hrefLang={hrefLang} templateGenres={templateGenres} />
);

IndexPage.propTypes = {
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  hrefLang: PropTypes.string.isRequired,
  templateGenres: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.oneOf(Object.values(TemplateGenreSlugEnum)).isRequired,
      coverImageSquared: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export const getStaticProps: GetStaticProps = async () => {
  // init vars
  const locale = 'en';
  const hrefLang = RouteEnum.WEBSITE_HOME_DE;

  // fetch posts
  const blogPostsLang = await fetchBlogPosts({
    limit: 3,
    sort: { key: 'createdAt', direction: 'desc' },
  });
  const blogPosts = blogPostsLang
    ?.filter((item) => item.locale === locale)
    .map((item) => item.post);

  // fetch genres
  const { items: templateGenres } = await fetchTemplateGenres({ locale, limit: 3 });

  return {
    props: {
      blogPosts,
      hrefLang,
      templateGenres: templateGenres.map((item) => ({
        ...item,
        createdAt: new Date(item.createdAt as Date).toDateString(),
        lastModifiedAt: new Date(item.lastModifiedAt as Date).toDateString(),
      })),
    },
  };
};

export default IndexPage;
